import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { logoutUser } from '../utils';
import { AppContext } from '../context';

const Logout = () => {
  const { setAuthenticated } = useContext(AppContext);

  useEffect(() => setAuthenticated(logoutUser()), []);

  return (
    <Redirect to="/" />
  );
};

export default Logout;
