/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import { useDebouncedEffect } from '../../hooks';
import { api } from '../../utils';

const StyledEditLabel = styled.div`
  border: 1px solid #cfd8dc;
  border-top: 0;

  fieldset {
    border: none;
  }

  input {
    height: 25px;
    margin-bottom: 5px;
  }

  .section {
    margin-top: 30px;
  }
`;

const EditUser = ({ slotId, onCancel, onSave }) => {
  const [userName, setUserName] = React.useState('');
  const [user, setUser] = React.useState(null);
  const [users, setUsers] = React.useState([]);

  useDebouncedEffect(
    () => {
      async function fetchUsers() {
        if (userName) {
          const { status, ...usersResponse } = await api.POST('admin/search-users', { search: userName });
          setUsers(usersResponse);
        }
      }
      fetchUsers();
    },
    [userName],
    200,
  );

  const saveCustomLabel = async () => {
    const { days } = await api.POST(`admin/reserve-user/${slotId}`, { userId: user });

    onSave({ days });
  };

  const items = Object.keys(users)
    .map((key) => ({ key, label: `${users[key].label} (${users[key].weeks})` }));

  const defaultProps = {
    options: items,
    getOptionLabel: (option) => option.label,
  };

  return (
    <StyledEditLabel className="row">
      <div className="container">

        <Autocomplete
          {...defaultProps}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Adaugă persoană"
              size="small"
              onChange={(event) => {
                setUserName(event.target.value);
              }}
            />
          )}
          onChange={(event, newValue) => {
            setUser(newValue.key);
          }}
        />

        <div className="section center-align">
          <button className="btn waves-effect waves-light" type="button" onClick={onCancel} style={{ marginRight: '30px' }}>
            Cancel
            <i className="material-icons right">delete</i>
          </button>
          <button className="btn waves-effect waves-light" type="button" onClick={saveCustomLabel}>
            Salvează
            <i className="material-icons right">add</i>
          </button>
        </div>

      </div>
    </StyledEditLabel>
  );
};

export default EditUser;
