import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { api } from '../../../utils';
import Interval from './Interval';
import 'react-datepicker/dist/react-datepicker.css';
import { daysAscending, getTodayDate } from '../../../utils/common';

const daysMap = {
  0: 'Duminică',
  1: 'Luni',
  2: 'Marți',
  3: 'Miercuri',
  4: 'Joi',
  5: 'Vineri',
  6: 'Sâmbătă',
};

const monthsMap = {
  0: 'Ianuarie',
  1: 'Februarie',
  2: 'Martie',
  3: 'Aprilie',
  4: 'Mai',
  5: 'Iunie',
  6: 'Iulie',
  7: 'August',
  8: 'Septembrie',
  9: 'Octombrie',
  10: 'Noiembrie',
  11: 'Decembrie',
};

/**
 * Generates a user-friendly textual description of the given date object.
 *
 * @param {*} date - JS date object
 * @returns {string}
 */
const dayNameFromDate = (date) => {
  const dayName = daysMap[date.getDay()];
  const monthName = monthsMap[date.getMonth()];

  return `${dayName}, ${date.getDate()} ${monthName}`;
};

const Calendar = ({ type = 'new' }) => {
  const [message, setMessage] = useState('');
  const [uniqueIndex, setUniqueIndex] = useState(0);
  const [calendar, setCalendar] = useState({ days: [], id: null });
  const [dataVersion, setDataVersion] = useState(0);
  const [fetchingData, setFetchingData] = useState(true);

  const today = getTodayDate();

  useEffect(() => {
    async function fetchData() {
      setFetchingData(true);
      const response = await api.GET(`admin/table?type=${type}`);
      response.days = response.days.sort(daysAscending);
      setCalendar(response);
      setFetchingData(false);
    }
    fetchData();
  }, [dataVersion]);

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    const filterIntervalIds = (day) => ({
      ...day,
      intervals: day.intervals.map(
        ({ id, ...interval }) => (id.toString().startsWith('new-') ? interval : { id, ...interval }),
      ),
    });

    const data = {
      calendar: {
        id: calendar.id,
        days: calendar.days.map(
          ({ id, ...day }) => (id.toString().startsWith('new-') ? filterIntervalIds(day) : { id, ...filterIntervalIds(day) }),
        ),
      },
    };

    await api.POST(`admin/table?type=${type}`, data);

    setDataVersion(dataVersion + 1);

    return setMessage('Salvat cu succes! (Zilele au fost reordonate dupa dată)');
  };

  const setDay = (data, dayIndex) => {
    setCalendar({
      ...calendar,
      days: calendar.days.map((day) => (day.id === dayIndex ? { ...day, ...data } : day)),
    });
  };

  const setInterval = (key, value, dayIndex, intervalIndex) => {
    setCalendar({
      ...calendar,
      days: calendar.days.map((day) => (day.id === dayIndex
        ? {
          ...day,
          intervals: day.intervals.map(
            (interval) => (interval.id === intervalIndex
              ? { ...interval, [key]: value }
              : interval),
          ),
        }
        : day
      )),
    });
  };

  const addDay = (dayId) => () => {
    setUniqueIndex(uniqueIndex + 1);
    setCalendar({
      ...calendar,
      days: [...calendar.days, {
        id: `new-${dayId}`, name: '', date: today, intervals: [],
      }],
    });
  };

  const addInterval = (dayId, intervalId) => () => {
    setUniqueIndex(uniqueIndex + 1);
    setCalendar({
      ...calendar,
      days: calendar.days.map((day) => (day.id === dayId
        ? {
          ...day,
          intervals: [...day.intervals, {
            id: `new-${intervalId}`, starts: '00:00', ends: '00:00', time: 5, limits: 2,
          }],
        }
        : day)),
    });
  };

  const removeDay = (dayId) => () => {
    setCalendar({
      ...calendar,
      days: calendar.days.map((item) => (item.id === dayId ? { ...item, remove: true } : item)),
    });
  };

  const removeInterval = (dayId, intervalId) => () => {
    const daysCopy = JSON.parse(JSON.stringify(calendar.days));
    const day = daysCopy.find((item) => item.id === dayId);
    const intervals = day.intervals.map(
      (item) => (item.id === intervalId ? { ...item, remove: true } : item),
    );
    day.intervals = intervals;
    setCalendar({
      ...calendar,
      days: daysCopy,
    });
  };

  const canDeleteDay = (day) => day.intervals.every((interval) => interval.status === 0);

  const calendarDays = calendar.days.filter((day) => !day.remove);

  if (fetchingData) {
    return null;
  }

  return (
    <Container className="container">

      <form onSubmit={handleOnSubmit} className="col s12">
        {calendarDays.map((day) => (
          <div key={day.id} className="input-field col s12">

            <div className="name-date">
              <div className="input-field">
                <label htmlFor={`date${day.id}`} className="active">Data</label>
                <DatePicker
                  selected={day.date ? new Date(day.date) : new Date(today)}
                  id={`date${day.id}`}
                  onChange={(date) => setDay({ date, name: dayNameFromDate(date) }, day.id)}
                  autoComplete="off"
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="input-field name">

                <label htmlFor={`name${day.id}`} className={day.name ? 'active' : ''}>Nume</label>
                <input
                  type="text"
                  id={`name${day.id}`}
                  value={day.name}
                  onChange={(event) => setDay({ name: event.target.value }, day.id)}
                />
                {canDeleteDay(day) && (
                <a
                  title="Șterge zi"
                  onClick={removeDay(day.id)}
                >
                  <i className="material-icons prefix">delete</i>
                </a>
                )}
              </div>
            </div>

            <div className="col s6 offset-s6" style={{ backgroundColor: '#f5f5f5', padding: '10px 20px', marginBottom: '30px' }}>
              <h5 className="left-align">Intervale:</h5>

              {day.intervals.filter((interval) => !interval.remove).map((interval, index) => (
                <Interval
                  interval={interval}
                  day={day}
                  key={index}
                  setInterval={setInterval}
                  removeInterval={removeInterval}
                />
              ))}

              <div className="section right-align">
                <button className="btn waves-effect waves-light light-blue" type="button" onClick={addInterval(day.id, uniqueIndex)}>
                  Adaugă interval
                  <i className="material-icons right">add</i>
                </button>
              </div>

            </div>
          </div>
        ))}

        <div className="section warning">
          {message}
        </div>

        <div className="section center-align">
          <button className="btn waves-effect waves-light" type="button" onClick={addDay(uniqueIndex)} style={{ marginRight: '30px' }}>
            Adaugă zi
            <i className="material-icons right">add</i>
          </button>
          <button className="btn waves-effect waves-light amber darken-4" type="submit">
            Salvare
            <i className="material-icons right">send</i>
          </button>
        </div>

        <div className="section center-align">
          <Link to={{
            pathname: '/table-draft',
            state: {
              type,
            },
          }}
          >
            <button className="btn light-blue" type="button">
              Vizualizare calendar în lucru
              <i className="material-icons right">search</i>
            </button>
          </Link>
        </div>

      </form>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 40px;

  .name-date {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    .name {
      flex-grow: 1;
      padding-left: 20px;
    }
  }
`;

export default Calendar;
