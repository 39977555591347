import React from 'react';
import styled from 'styled-components';

interface IconButtonProps {
    icon: string,
    description: string,
    onClick: Function
}

const IconButton = (props: IconButtonProps) => {
    const { description, onClick, icon } = props;

    return (
        <Button type="button" className="transparent" title={description} onClick={() => onClick()}>
            <i className="material-icons prefix light-blue-text">{icon}</i>
        </Button>
    );
}

const Button = styled.button`
    width: 3rem;
    border: none;
    box-sizing: content-box;
    margin: 0;

    .prefix {
        position: relative;
        top: 4px;
    }
`;

export default IconButton;