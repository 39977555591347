import axios from 'axios';
import { getToken } from './auth';

const isDev = false;

const PATH = isDev ? 'http://localhost:443/api/' : 'https://node.ascorcluj.com/api/';

const instance = axios.create({
  baseURL: PATH,
  // If the server does not respond in 2sec the request will have "Canceled" status
  timeout: 2000,
});

/**
 * Recursive try to GET the resource 3 times
 * @param {*} endpoint
 * @param {*} count
 * @returns
 */
const apiGET = async (endpoint, count = 0) => {
  try {
    return instance
      .get(
        endpoint,
        { headers: { Authorisation: getToken() } },
      ).then((res) => res.data);
  } catch (error) {
    if (error || error.status !== 200) {
      console.error('failed, retry');

      // eslint-disable-next-line no-param-reassign
      if (count++ < 3) {
        return apiGET(endpoint, count);
      }
      throw new Error('max retries reached');
    } else {
      throw error;
    }
  }
};

const apiPOST = (endpoint, data) => instance
  .post(
    endpoint,
    data,
    { headers: { Authorisation: getToken() } },
  )
  .then((res) => ({
    ...res.data,
    status: 200,
  }))
  .catch(() => ({ status: 400 }));

const apiPUT = (endpoint, data) => instance
  .put(
    endpoint,
    data,
    { headers: { Authorisation: getToken() } },
  )
  .then((res) => ({
    ...res.data,
    status: 200,
  }))
  .catch(() => ({ status: 400 }));

const apiDELETE = (endpoint) => instance
  .delete(
    endpoint,
    { headers: { Authorisation: getToken() } },
  )
  .then((res) => ({
    ...res.data,
    status: 200,
  }))
  .catch(() => ({ status: 400 }));

function throttle(fn, ms) {
  let timeout;
  function exec(...args) {
    fn.apply(this, args);
  }
  function clear() {
    // eslint-disable-next-line no-unused-expressions
    timeout === undefined ? null : clearTimeout(timeout);
  }
  if (fn !== undefined && ms !== undefined) {
    timeout = setTimeout(exec, ms);
  } else {
    console.error('callback function and the timeout must be supplied');
  }
  // API to clear the timeout
  throttle.clearTimeout = function () {
    clear();
  };
}

const debounce = (fn, time = 200) => {
  let timeout = null;

  // Don't change this in an arrow function, you will loose the context
  return function (...args) {
    const functionCall = () => fn.apply(this, args);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export const api = {
  POST: apiPOST,
  PUT: apiPUT,
  DELETE: apiDELETE,
  GET: apiGET,
  throttle,
  debounce,
};
