import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { api } from '../utils';
import InputField from './common/InputField.tsx';
import IconButton from './common/IconButton.tsx';

const Profile = () => {
  const [message, setMessage] = useState(null);
  const [isErrorMessage, setIsErrorMessage] = useState(null);
  const [index, setIndex] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);

  const [profile, setProfile] = useState({
    email: '',
    phone: '',
    users: [],
  });

  useEffect(() => {
    api.GET('profile').then((data) => {
      setProfile(data);
      setFetchingData(false);
    });
  }, []);

  const setEmail = (email) => setProfile({ ...profile, email });
  const setPhone = (phone) => setProfile({ ...profile, phone });

  const updateUser = (name, id) => {
    const updatedUsers = profile.users.map((user) => (user.id === id ? { ...user, name } : user));
    setProfile({ ...profile, users: updatedUsers });
  };

  const addUser = () => {
    setProfile({ ...profile, users: [...profile.users, { id: `new-${index}`, name: '', active: 0 }] });
    setIndex(index + 1);
  };
  const removeUser = (id) => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (!confirm('Sunteți sigur că doriți să ștergeți persoana?')) {
      return;
    }

    const updatedUsers = profile.users.map(
      (user) => (user.id === id ? { ...user, active: -1 } : user),
    );
    setProfile({ ...profile, users: updatedUsers });
  };

  const showMessage = (message, isError = false) => {
    setMessage(message);
    setIsErrorMessage(isError);
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    if (submitting) {
      return;
    }

    const data = {
      ...profile,
      users: profile.users.map((user) => (user.id.toString().startsWith('new-') ? { name: user.name, active: user.active } : user)),
    };

    setSubmitting(true);

    const { status, ...newProfile } = await api.POST('profile', data);

    setSubmitting(false);

    if (status === 200) {
      showMessage('Datele au fost salvate cu succes!');
      setProfile(newProfile);
    } else {
      showMessage('A apărut o problemă!', true);
    }
  };

  if (fetchingData) {
    return null;
  }

  const activeUsers = profile.users.filter(({ active }) => active === 1);
  const inactiveUsers = profile.users.filter(({ active }) => active === 0);

  return (
    <Container className="container">
      <h4 className="left-align">Profil</h4>

      <form onSubmit={handleOnSubmit}>
        <InputField label="Email" value={profile.email} onChange={setEmail} />
        <InputField type="tel" label="Telefon" value={profile.phone} onChange={setPhone} />

        <div className="left-align change-password">
          <Link to="/change-password/">Schimbați parola</Link>
        </div>

        {activeUsers.length > 0 && <h5 className="left-align">Persoane asociate contului:</h5>}

        {activeUsers.map(({ id, name }, index) => (
          <InputField label="Nume Prenume" value={name} onChange={(name) => updateUser(name, id)} key={index}>
            {activeUsers.length > 1 && (
              <IconButton icon="delete" description="Șterge persoană" onClick={() => removeUser(id)} />
            )}
          </InputField>
        ))}

        {inactiveUsers.length > 0 && <h5 className="left-align">Persoane în curs de aprobare:</h5>}

        {inactiveUsers.map(({ id, name }, index) => (
          <InputField label="Nume Prenume" value={name} onChange={(name) => updateUser(name, id)} key={index}>
            <IconButton icon="delete" description="Șterge persoană" onClick={() => removeUser(id)} />
          </InputField>
        ))}

        {message && (
          <div className={`section ${isErrorMessage ? 'warning' : 'success'}`}>
            {message}
          </div>
        )}

        <div className="section">
          <button className="btn waves-effect waves-light light-blue" type="button" onClick={addUser}>
            Adaugă o persoană
            <i className="material-icons right">add</i>
          </button>
          <button className="btn waves-effect waves-light" type="submit">
            Salvați modificările
            <i className="material-icons right">send</i>
          </button>
        </div>

      </form>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;

  .change-password {
    padding: 10px 0;
  }

  .section {
    button {
      margin: 10px;
    }
  }

  h5 {
    margin-bottom: 30px;
  }
`;

export default Profile;
