import React, { useEffect, useState } from 'react';

const dictionary = {
  allowed: 'Acum',
  'not-allowed': 'Lista viitoare',
  'no-slots': 'Nu mai sunt locuri',
};

const secondsToTime = (seconds) => {
  const min = Math.floor(seconds / 60);
  const sec = seconds % 60;

  return `${min > 0 ? `${min} min` : ''} ${sec} sec`;
};

const ScheduleHistory = (props) => {
  const { history, refetchData } = props;
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval = null;

    // if counter reaches 0, we want to refetch data
    history
      .forEach((hist) => {
        if (Number.isInteger(hist.time)) {
          if (hist.time - seconds === 0) {
            refetchData();
            setSeconds(0);
          }
        }
      });

    interval = setInterval(() => {
      setSeconds(seconds + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  const displayHistory = history
    .map((hist) => {
      if (!Number.isInteger(hist.time)) {
        return hist;
      }

      const countDown = hist.time > seconds ? `în ${secondsToTime(hist.time - seconds)}` : 'allowed';

      return {
        ...hist,
        time: countDown,
      };
    });

  return (
    <>
      <h4 className="left-align">Istoric programări</h4>
      <table>
        <thead>
          <tr>
            <th>Când</th>
            <th>Cine</th>
            <th>Programabil</th>
          </tr>
        </thead>
        <tbody>
          {displayHistory.map(({ weeks, names, time }, index) => weeks > 0 && (
          <tr key={index}>
            <td>{`${weeks === '99' ? 'Mai mult de 5 ' : `Acum ${weeks} `} ${weeks === '1' ? 'listă' : 'liste'}`}</td>
            <td>{names}</td>
            <td>{dictionary[time] ? dictionary[time] : time}</td>
          </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ScheduleHistory;
