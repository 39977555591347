import React from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

interface InputFieldProps {
  label: string,
  type: string,
  value?: string,
  onChange: Function,
  children?: Array<any>
}

const InputField = (props: InputFieldProps) => {
    const key = uuid(),
      { label, type = 'text', value, onChange } = props;

    return (
        <div className="input-field">

            <label htmlFor={key} className={value ? 'active' : ''}>{label}</label>
            <InputWrapper>
              <input
                type={type}
                id={key}
                value={value}
                onChange={(event) => onChange(event.target.value)}
              />
              {props.children && 
                <div className="action-buttons">
                  {props.children}
                </div>
              }
            </InputWrapper>
        </div>
    );
}

const InputWrapper = styled.div`
  display: flex;

  input {
    padding: 0 0.75rem;
  }

  .action-buttons {
    flex-shrink: 1;
    align-self: center;
  }
`;

export default InputField;