/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { api } from '../../utils';

const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await api.GET('admin/notifications');
      setNotifications(response);
    }
    fetchData();
  }, []);

  const markAsRead = (id) => async (event) => {
    event.preventDefault();

    // eslint-disable-next-line no-restricted-globals, no-alert
    if (!confirm('Sunteți sigur?')) {
      return;
    }

    const response = await api.PUT(`admin/notifications/${id}`, { active: 0 });

    setNotifications(response.notifications);
  };

  return {
    notifications,
    markAsRead,
  };
};

const Notifications = () => {
  const {
    notifications,
    markAsRead,
  } = useNotifications();

  return (
    <Container className="container">
      <h4 className="left-align">Notificari</h4>
      <ul>
        {notifications.map(({
          id, userName, dayName, unreadNotification, slotName, notificationDate,
        }) => (
          <li key={id} className={unreadNotification && 'unread'}>
            <span>
              {dayName}
              {' '}
              {slotName}
            </span>
            <span>
              {userName}
            </span>
            <span>
              {notificationDate.substring(0, 10)}
            </span>
            {unreadNotification ? (
              <i className="material-icons right" onClick={markAsRead(id)} title="Mark as read">check</i>
            ) : (
              <div>citit</div>
            )}
          </li>
        ))}
      </ul>
    </Container>
  );
};

const Container = styled.div`
  li {
    text-align: left;
    margin: 20px 0;
    font-weight: normal;
    display: flex;

    i {
      height: 20px;
      cursor: pointer;
      color: blue;
    }

    span {
      width: 33%;
    }

    &.unread {
      font-weight: bold;
    }
  }
`;

export default Notifications;
