import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { authenticatedUser } from '../utils';

const Help = ({ isSignedUp }) => {
  const isAuthenticated = authenticatedUser();

  const withPage = (url, page) => (isAuthenticated
    ? (<Link to={url}>{page}</Link>)
    : (<b>{page}</b>));

  const withSignupPage = (url, page) => (isAuthenticated
    ? (<b>{page}</b>)
    : (<Link to={url}>{page}</Link>));

  return (
    <Container className="container">
      <p className="intro">
        Prin intermediul acestei aplicații vă puteți programa la spovedanie,
        la Biserica Studenților din complexul studențesc Hașdeu, Cluj-Napoca.

      </p>
      <h5>{isSignedUp ? 'Înscriere cu succes' : 'Cum funcționează aplicația'}</h5>

      <div className="signup-message">
        {isSignedUp ? 'Înscrierea s-a realizat cu succes. Sunteți automat logat în aplicație.' : ''}
      </div>

      <ol>
        {!isSignedUp && (
        <li>
          Vă creați un cont pe pagina de
          {' '}
          {withSignupPage('/signUp', 'Înscriere')}
          .
          Identificarea în aplicație se face pe baza adresei de email.
          Nu pot exista mai multe conturi cu aceeași adresă de email. Numărul de telefon
          vi se cere pentru a vi se putea comunica în cel mai scurt timp eventuale
          schimbări.
        </li>
        )}
        <li>
          După ce crearea contului va fi aprobată de către Administrator, veți avea acces
          la pagina de
          {' '}
          {withPage('/schedule', 'Programare')}
          .
        </li>
        <li>
          Programările se pot efectua după publicarea tabelului pentru lista (săptămâna) următoare.
        </li>
        <li>
          Se poate efectua  câte o programare pentru fiecare persoană asociată contului.
        </li>
        <li>
          Se poate renunța la o programare activă, moment în care persoana respectivă
          poate fi reprogramată, dacă sunt locuri disponibile.
        </li>
      </ol>

      <h5>Persoanele asociate unui cont</h5>
      <ol>
        <li>
          La crearea contului, sunteți automat asociat cu numele și prenumele introduse în
          formularul de
          {' '}
          {withSignupPage('/signUp', 'Înscriere')}
          .
        </li>
        <li>
          În pagina de
          {' '}
          {withPage('/profile', 'Profil')}
          {' '}
          puteți adăuga și alte Persoane asociate contului. După ce vor fi aprobate de către
          Administrator, puteți efectua o programare pentru oricare din aceste persoane.
        </li>
        <li>
          Persoane asociate contului pot fi de exemplu copiii, părinții, o persoană
          apropiată care nu are acces direct la aplicație și pentru care dorim să
          facem o programare.
        </li>
        <li>
          Se recomandă ca o persoană să fie asociată unui singur cont.
        </li>
        <li>
          De exemplu,
          într-o familie titularul contului este unul dintre soți și ceilalți membri
          ai familiei sunt persoane asociate. Accesul în aplicație se poate face
          de pe mai multe device-uri simultan, cu același cont.
        </li>
      </ol>

      <h5>Contact</h5>

      <p className="contact">
        Pentru orice problemă tehnică, scrieți un mail la
        <em><b> programariascor@gmail.com</b></em>
      </p>

      <br />

      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video controls>
        <source src="https://ascor-media.s3.eu-west-3.amazonaws.com/tutorial-ascor.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

    </Container>
  );
};

const Container = styled.div`
  .intro, .contact {
    padding-top: 20px;
    color: #888;
    text-align: left;
  }
  .contact {
    padding-top: 0;
  }
  .signup-message {
      font-weight: bold;
      text-align: left;
  }

  ol {
      margin-bottom: 40px;

      li {
          text-align: left;
          margin-bottom: 10px;
      }
  }

  video {
      width: 100%;
  }

  h5 {
    margin: 30px 0;
    text-align: left;
  }
`;

export default Help;
