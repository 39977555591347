import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    text-align: left;
    width: 80vw;
    margin: auto;

    h1 {
        font-size: 30px;
        font-weight: bold;
    }
    h2 {
        font-size: 20px;
        font-weight: bold;
    }
`;

const Terms = () => (
  <Container>
    <h1> Politica de confidențialitate pentru Aplicația Programări </h1>

    <p>
      Una dintre principalele priorități ale
      <b>  Aplicației Programări</b>
      , accesibilă de pe https://programari.ascorcluj.com/, este confidențialitatea vizitatorilor
      noștri. Acest document privind politica de confidențialitate conține tipuri de informații
      colectate și înregistrate de Aplicația Programări și modul în care le folosim.
      {' '}
    </p>

    <p>
      Dacă aveți întrebări suplimentare sau aveți nevoie de mai multe informații despre politica
      noastră de confidențialitate,
      nu ezitați să ne contactați. Politica noastră de confidențialitate a fost generată cu ajutorul

      <a href="https://www.gdprprivacynotice.com/"> Generatorul de politici de confidențialitate GDPR din GDPRPrivacyNotice.com </a>
    </p>

    <h2> Regulamentul general privind protecția datelor (GDPR) </h2>
    <p> Suntem un operator de date al informațiilor dvs. </p>

    <p>
      {' '}
      Baza legală pentru colectarea și utilizarea informațiilor personale
      descrise în această politică de confidențialitate depinde de informațiile
      personale pe care le colectăm și de contextul specific în care colectăm informațiile:
    </p>
    <ul>
      <li> Aplicația Programări trebuie să încheie un contract cu dvs. </li>
      <li> Ați acordat Aplicației Programări permisiunea de a face acest lucru </li>
      <li>
        Prelucrarea informațiilor dvs. personale este în
        interesul legitim al Aplicației Programări
      </li>
      <li> Aplicația Programări trebuie să respecte legea </li>
    </ul>

    <p>
      {' '}
      Aplicația Programări va păstra informațiile dvs. personale numai atât timp cât este
      necesar în scopurile stabilite în prezenta politică de confidențialitate.
      Vom păstra și vom utiliza informațiile dvs. în măsura necesară pentru a ne
      respecta obligațiile legale, pentru a soluționa litigiile și pentru a ne aplica politicile.
    </p>

    <p>
      {' '}
      Dacă sunteți rezident al Spațiului Economic European (SEE), aveți
      anumite drepturi de protecție a datelor. Dacă doriți să fiți informați
      despre ce informații personale deținem despre dvs. și dacă doriți ca
      acestea să fie eliminate din sistemele noastre, vă rugăm să ne contactați.
    </p>
    <p> În anumite circumstanțe, aveți următoarele drepturi de protecție a datelor: </p>
    <ul>
      <li> Dreptul de a accesa, actualiza sau șterge informațiile pe care le avem despre dvs. </li>
      <li> Dreptul de rectificare. </li>
      <li> Dreptul de a obiecta. </li>
      <li> Dreptul de restricție. </li>
      <li> Dreptul la portabilitatea datelor </li>
      <li> Dreptul de a retrage consimțământul </li>
    </ul>

    <h2> Fișiere jurnal </h2>

    <p>
      {' '}
      Aplicația Programări urmează o procedură standard de utilizare a fișierelor jurnal.
      Aceste fișiere înregistrează vizitatori când accesează site-uri web.
      Toate companiile de găzduire fac asta și o parte din analiza serviciilor de găzduire.
      Informațiile colectate de fișierele jurnal includ adrese de protocol
      internet (IP), tip de browser, furnizor de servicii Internet (ISP),
      ștampilă de dată și oră, pagini de trimitere / ieșire și, eventual, numărul de clicuri.
      Acestea nu sunt legate de nicio informație care poate fi identificată personal.
      Scopul informațiilor este de a analiza tendințele, de a administra site-ul, de a
      urmări mișcarea utilizatorilor de pe site și de a culege informații demografice.
    </p>

    <h2> Cookie-uri și semnalizatoare web </h2>

    <p>
      {' '}
      La fel ca orice alt site web, Aplicația Programări folosește „cookie-uri”.
      Aceste cookie-uri sunt utilizate pentru a stoca informații,
      inclusiv preferințele vizitatorilor, și paginile de pe site-ul web pe care
      vizitatorul le-a accesat sau le-a vizitat. Informațiile sunt utilizate pentru a
      optimiza experiența utilizatorilor prin personalizarea conținutului paginii
      noastre web pe baza tipului de browser al vizitatorilor și / sau a altor informații.
    </p>

    <p>
      Pentru informații mai generale despre cookie-uri, vă rugăm să citiți

      <a href="https://www.cookieconsent.com/what-are-cookies/"> „Ce sunt cookie-urile” </a>
      .
    </p>

    <h2> Politici de confidențialitate </h2>

    <p>
      {' '}
      Puteți consulta această listă pentru a găsi Politica de confidențialitate
      pentru fiecare dintre partenerii de publicitate ai Aplicației Programări.
    </p>

    <p>
      {' '}
      Serverele de publicitate sau rețelele publicitare de la terțe părți utilizează
      tehnologii precum cookie-uri, JavaScript sau web beacon-uri care sunt
      utilizate în reclamele și linkurile respective care apar pe Aplicația Programări,
      care sunt trimise direct în browserul utilizatorilor. Ei primesc automat adresa dvs.
      IP atunci când se întâmplă acest lucru. Aceste tehnologii sunt utilizate pentru a
      măsura eficiența campaniilor lor publicitare și / sau pentru a personaliza
      conținutul publicitar pe care îl vedeți pe site-urile web pe care le vizitați.
    </p>

    <p>
      {' '}
      Rețineți că Aplicația Programări nu are acces sau control asupra acestor
      cookie-uri utilizate de agenții de publicitate terți.
    </p>

    <h2> Politicile de confidențialitate ale terților </h2>

    <p>
      {' '}
      Politica de confidențialitate a Aplicației Programări nu se aplică altor agenți de
      publicitate sau site-uri web. Astfel, vă sfătuim să consultați Politicile de
      confidențialitate respective ale acestor servere de anunțuri terțe pentru
      informații mai detaliate. Poate include practicile și instrucțiunile lor
      despre cum să renunțați la anumite opțiuni.
    </p>

    <p>
      {' '}
      Puteți alege să dezactivați cookie-urile prin opțiunile individuale ale browserului.
      Pentru a afla informații mai detaliate despre gestionarea cookie-urilor cu anumite
      browsere web, acestea pot fi găsite pe site-urile web respective ale browserelor.
    </p>

    <h2> Informații pentru copii </h2>

    <p>
      {' '}
      O altă parte a priorității noastre este adăugarea de protecție pentru copii în timpul
      utilizării internetului. Îi încurajăm pe părinți și tutori să observe, să participe
      și / sau să monitorizeze și să îndrume activitatea lor online.
    </p>

    <p>
      {' '}
      Aplicația Programări nu colectează în cunoștință de cauză informații personale de
      identificare de la copiii cu vârsta sub 13 ani. Dacă credeți că copilul dvs. a
      furnizat acest tip de informații pe site-ul nostru, vă încurajăm să ne contactați
      imediat și vom face tot posibilul. eforturile de eliminare promptă a acestor
      informații din înregistrările noastre.
    </p>

    <h2> Politica de confidențialitate online </h2>

    <p>
      {' '}
      Politica noastră de confidențialitate se aplică numai activităților noastre
      online și este valabilă pentru vizitatorii site-ului nostru web cu privire la
      informațiile pe care le-au distribuit și / sau colectat în Aplicația Programări.
      Această politică nu se aplică informațiilor colectate offline sau prin alte
      canale decât acest site web.
    </p>

    <h2> Consimțământ </h2>

    <p>
      {' '}
      Prin utilizarea site-ului nostru web, sunteți de acord cu politica noastră de
      confidențialitate și sunteți de acord cu termenii acesteia.
    </p>
  </Container>
);

export default Terms;
