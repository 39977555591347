import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../context';
import { api } from '../utils';

const ChangePassword = ({ match: { params: { id } } }) => {
  const { isAdminUser } = useContext(AppContext);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    // eslint-disable-next-line no-restricted-globals
    if (!confirm('Schimbați parola?')) {
      return;
    }

    if (password.length < 4) {
      setMessage('Parola este prea scurtă!');
      return;
    }

    if (password !== confirmPassword) {
      setMessage('Nu ați confirmat noua parolă!');
      setConfirmPassword('');
      return;
    }

    const data = {
      password,
    };

    const apiUrl = isAdminUser
      ? `admin/change-password/${id}`
      : 'change-password';

    const { status } = await api.POST(apiUrl, data);

    if (status === 200) {
      setMessage('Parola a fost schimbată cu succes!');
    } else {
      setMessage('A apărut o problemă!');
    }
  };

  return (
    <Container className="container">
      <h4 className="left-align">Schimbați Parola </h4>
      <form onSubmit={handleOnSubmit}>

        <div className="input-field">
          <label htmlFor="password" className={password ? 'active' : ''}>Parola nouă</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>

        <div className="input-field">
          <label htmlFor="confirmPassword" className={confirmPassword ? 'active' : ''}>Confirmă parola</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
        </div>

        {message && (
          <div className="section warning">
            {message}
          </div>
        )}

        <div className="section">
          <button className="btn waves-effect waves-light" type="submit">
            Schimbă parola
            <i className="material-icons right">send</i>
          </button>
        </div>

      </form>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 40px;


  @media only screen and (max-width: 768px) {
    form {
      width: 90%;
    }
  }  
`;

export default ChangePassword;
