const TOKEN = 'token';
const REFRESH_TOKEN = 'refreshToken';
const IS_ADMIN = 'isAdmin';

export const getCookie = (name) => {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
};

export const setCookie = (name, value, hours) => {
  const d = new Date();
  d.setTime(d.getTime() + 60 * 60 * 1000 * hours);
  document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
};

export const getToken = () => getCookie(TOKEN);
export const isAdmin = () => parseInt(getCookie(IS_ADMIN), 10) === 1;
export const authenticatedUser = () => Boolean(getToken());

export const withAuth = (Comp) => (props) => {
  if (authenticatedUser()) {
    return Comp(props);
  }
  props.history.push('/');
  return null;
};

export const withAdmin = (Comp) => (props) => {
  if (authenticatedUser() && isAdmin()) {
    return Comp(props);
  }
  props.history.push('/');
  return null;
};

export const authenticateUser = (token, refreshToken, admin) => {
  const nextYear = 3600 * 1000 * 24 * 365;
  setCookie(TOKEN, token, nextYear);
  setCookie(REFRESH_TOKEN, refreshToken, nextYear);
  setCookie(IS_ADMIN, admin, nextYear);
  return true;
};

export const deleteCookie = (name) => { setCookie(name, '', -1); };

export const logoutUser = () => {
  deleteCookie(TOKEN);
  deleteCookie(REFRESH_TOKEN);
  deleteCookie(IS_ADMIN);
  return false;
};

export const isEmail = (val) => {
  // eslint-disable-next-line no-useless-escape
  const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEmail.test(val);
};
