import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { api } from '../../utils';
import { COLORS } from '../colors';
import EditSlotForm from './EditSlotForm';
import { daysAscending } from '../../utils/common';

const TableDraft = ({ history, location: { state } }) => {
  const [day, setDay] = useState('');
  const [slots, setSlots] = useState([]);
  const [editSlot, setEditSlot] = useState();
  const [schedule, setSchedule] = useState({
    days: [],
  });
  const [checkedSlots, setCheckedSlots] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [dataVersion, setDataVersion] = useState(0);

  const type = state.type || 'new';

  const checkSlot = (checkSlotId) => (event) => {
    if (event.target.checked) {
      setCheckedSlots([...checkedSlots, checkSlotId]);
    } else {
      setCheckedSlots(checkedSlots.filter((item) => item !== checkSlotId));
    }
  };

  const publishTable = async () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm('Sunteți sigur că doriți publicarea tabelului?')) {
      await api.POST(`admin/table-draft?type=${type}`);

      history.push('/table-published');
    }
  };

  const reserveSlots = async () => {
    if (checkedSlots.length === 0) {
      // eslint-disable-next-line no-alert
      alert('Nu sunt intervale selectate');
      return;
    }
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm('Sunteți sigur că doriți să rezervați intervalele selectate?')) {
      await api.POST('admin/reserve-slots', { slots: checkedSlots });

      setDataVersion(dataVersion + 1);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const response = await api.GET(`admin/table-draft?type=${type}`);
      response.days = response.days.sort(daysAscending);
      setSchedule(response);
      const [firstDay] = response.days;
      if (firstDay && !day) {
        setDay(firstDay.id);
      }
    }
    fetchData();
  }, [dataVersion]);

  useEffect(
    () => {
      const selectedSlots = selectAll
        ? slots.filter(({ active }) => active === 0).map(({ id }) => id)
        : [];
      setCheckedSlots(selectedSlots);
    }, [selectAll],
  );

  useEffect(
    () => {
      const displayDay = schedule.days.find(({ id }) => id === parseInt(day, 10));
      if (displayDay) {
        setSlots(displayDay.slots);
      }
    },
    [day, schedule],
  );

  return (
    <Container className="container">
      <h4 className="left-align">Tabel in lucru</h4>

      <div className="input-field col s12">
        <select className="browser-default" value={day} onChange={(event) => setDay(event.target.value)}>
          <option value="" disabled>Alegeți o zi</option>
          {schedule.days.map(({ id, name }) => (
            <option key={id} value={id}>{name}</option>
          ))}
        </select>
      </div>

      <div style={{ marginTop: '40px' }}>

        <div className="row">
          <input type="checkbox" id="select-all" checked={selectAll} className="filled-in" onChange={() => setSelectAll(!selectAll)} />
          <label htmlFor="select-all">Selectează toate</label>
        </div>

        {slots.map((slot) => {
          const status = slot.userName ? COLORS.INTERVAL : COLORS.USER;

          return (
            <React.Fragment key={slot.id}>
              <StyledTable className="row">
                <div className={`col s4 ${slot.color || status}`}>{slot.name.replace(' - 00:00', '') || '-'}</div>
                <div className={`col s7 ${slot.color || status}`}>
                  {slot.userName || '-' }
                  {slot.active === 0 && (
                  <i className="material-icons right" onClick={() => setEditSlot(slot.id)}>edit</i>
                  )}
                </div>
                {slot.active === 0 && (
                <div className="col s1">
                  <input type="checkbox" id={`check-${slot.id}`} value={slot.id} checked={checkedSlots.includes(slot.id)} className="filled-in" onChange={checkSlot(slot.id)} />
                  <label htmlFor={`check-${slot.id}`} />
                </div>
                )}
              </StyledTable>
              {editSlot === slot.id && (
              <EditSlotForm
                slotId={slot.id}
                label={slot.label}
                color={slot.color}
                onSave={(newSchedule) => {
                  setSchedule(newSchedule);
                  setEditSlot(null);
                  setDataVersion(dataVersion + 1);
                }}
                onCancel={() => {
                  setEditSlot(null);
                }}
              />
              )}
            </React.Fragment>
          );
        })}
      </div>

      <div className="section center-align">
        <button className="btn waves-effect waves-light" type="button" onClick={() => history.goBack()}>
          Înapoi
          <i className="material-icons right">arrow_back</i>
        </button>
        <button className="btn waves-effect waves-light" type="button" onClick={publishTable}>
          Publică Tabelul
          <i className="material-icons right">add</i>
        </button>
        <button className="btn waves-effect waves-light" type="button" onClick={reserveSlots}>
          Rezervă
          <i className="material-icons right">edit</i>
        </button>
      </div>

    </Container>
  );
};

const Container = styled.div`
  button {
    margin-right: 30px;
  }
`;

const StyledTable = styled.div`
  margin-bottom: 10px;

  && > div {
    padding: 10px;
    color: #FFF;
    font-weight: bold;

    i {
      height: 20px;
      cursor: pointer;
    }

    &:first-child {
      border-right: 1px solid #FFF;
    }
  }

  .col.s1 {
      padding-bottom: 0px;
    }
`;

export default TableDraft;
