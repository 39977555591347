import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { authenticateUser, api } from '../utils';
import { AppContext } from '../context';

const SignIn = ({ history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const { setAuthenticated } = useContext(AppContext);

  const { isAuthenticated, isAdminUser } = useContext(AppContext);

  if (isAuthenticated) {
    history.push(isAdminUser ? '/accounts' : '/schedule');
  }

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    const { token, refreshToken, isAdmin } = await api.POST('signin', { email, password });

    if (!token) {
      return setError('Email sau parolă incorecte!');
    }

    setAuthenticated(authenticateUser(token, refreshToken, isAdmin));

    return isAdmin ? history.push('/accounts') : history.push('/schedule');
  };

  return (
    <Container className="container">
      <h4>Autentificare</h4>
      <form onSubmit={handleOnSubmit}>

        <div className="input-field">
          <label htmlFor="email" className={email ? 'active' : ''}>Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>

        <div className="input-field">
          <label htmlFor="password" className={password ? 'active' : ''}>Parola</label>
          <input
            type={showPassword ? 'text' : 'password'}
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <i
            className="material-icons prefix right"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? 'visibility_off' : 'visibility'}
          </i>
        </div>

        {error && (
          <div className="error">
            {error}
          </div>
        )}

        <button className="btn waves-effect waves-light" type="submit">
          Trimite
          <i className="material-icons right">send</i>
        </button>

        <p>
          Dacă nu aveți cont, puteți să vă creați unul pe pagina de
          {' '}
          <Link to="/signUp">Înscriere</Link>
        </p>

      </form>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
  max-width: 400px;
  button {
    margin-top: 40px;
  }

  p {
    text-align: center;
    margin-top: 40px;
  }

  .input-field .prefix.right{
    right: 6px;
    top: 8px;
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
  }
`;

export default SignIn;
