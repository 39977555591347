/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { api } from '../../utils';
import { COLORS } from '../colors';

const SearchHeader = styled.li`
  display: flex;
  justify-content: space-between;
  
  div {
    width: 50%;
  }

  a {
    padding-top: 25px;
    cursor: pointer;
  }
`;

const Accounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [search, setSearch] = useState();
  const [searchedAccounts, setSearchedAccounts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await api.GET('admin/accounts');
      setAccounts(response || []);
    }
    fetchData();
  }, []);

  const searchUser = async () => {
    if (!search) {
      return;
    }

    const { status, ...searchedAccountsResult } = await api.POST('admin/search-accounts', { search });

    setSearchedAccounts(Object.values(searchedAccountsResult));
  };

  const inactiveAccounts = useMemo(() => accounts.filter(
    (account) => account.users.some((user) => user.active === 0),
  ), [accounts]);

  const activeAccounts = useMemo(() => accounts.filter(
    (account) => account.users.some((user) => user.active === 1),
  ), [accounts]);

  const inactiveUsers = useMemo(() => accounts.reduce(
    (acc, account) => acc + account.users.filter((user) => user.active === 0).length,
    0,
  ), [accounts]);

  const activeUsers = useMemo(() => accounts.reduce(
    (acc, account) => acc + account.users.filter((user) => user.active === 1).length,
    0,
  ), [accounts]);

  const updateUser = (accountId, userId, active) => {
    const accountsCopy = JSON.parse(JSON.stringify(accounts));
    const account = accountsCopy.find((item) => item.id === accountId);
    const user = account.users.find((item) => item.id === userId);
    user.active = active;
    setAccounts(accountsCopy);
  };

  const removeUser = (accountId, userId) => {
    const accountsCopy = JSON.parse(JSON.stringify(accounts));
    const account = accountsCopy.find((item) => item.id === Number(accountId));
    account.users = account.users.filter((item) => item.id !== Number(userId));
    setAccounts(accountsCopy);
  };

  const approveUser = (accountId, userId) => async () => {
    const { status } = await api.POST('admin/accounts', { userId, active: 1 });

    if (status === 200) {
      updateUser(accountId, userId, 1);
    }
  };

  const deactivateUser = (accountId, userId) => async () => {
    const { status } = await api.POST('admin/accounts', { userId, active: 0 });

    if (status === 200) {
      updateUser(accountId, userId, 0);
    }
  };

  const deleteUser = (accountId, userId) => async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Sunteți sigur că doriți să ștergeți persoana')) {
      await api.POST('admin/delete-user', { userId });

      removeUser(accountId, userId);
    }
  };

  const activeOrSearchedAccounts = search && searchedAccounts.length
    ? searchedAccounts : activeAccounts;

  return (
    <div className="container">

      <ul className="collection with-header" key="needs-approve">

        <li className="collection-header left-align">
          <h4>
            Necesită aprobare(
            {inactiveUsers}
            )
          </h4>
        </li>
        {inactiveAccounts.map(({
          id, email, phone, users,
        }) => (
          <Account
            key={id}
            approveUser={approveUser}
            deactivateUser={null}
            deleteUser={deleteUser}
            {...{
              id, email, phone, users,
            }}
          />
        ))}
      </ul>

      <ul className="collection with-header" key="active-accounts">

        <SearchHeader className="collection-header left-align">
          <h4>
            Utilizatori activi(
            {activeUsers}
            )
          </h4>

          <div className="input-field">
            <label htmlFor="search" className={search ? 'active' : ''}>Caută utilizator</label>
            <input
              type="text"
              id="search"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />

          </div>
          <a
            title="Caută user"
            onClick={searchUser}
          >
            <i className="material-icons right">search</i>
          </a>

        </SearchHeader>
        {activeOrSearchedAccounts.map(({
          id, email, phone, users,
        }) => (
          <Account
            key={id}
            approveUser={null}
            deactivateUser={deactivateUser}
            deleteUser={deleteUser}
            {...{
              id, email, phone, users,
            }}
          />
        ))}
      </ul>

    </div>
  );
};

const Account = ({
  id, email, phone, users, deactivateUser, approveUser, deleteUser,
}) => (
  <AccountItem className="collection-item left-align">
    <div className={`row ${COLORS.USER} header`}>
      {`${email} - ${phone}`}
      <Link to={`/change-password/${id}`} className="right"> [ Schimbați parola ]</Link>
    </div>

    <StyledList>
      {users.map(({ id: userId, name, active }) => (
        <li key={userId}>
          <div className="row">
            <div className="col s6"><b>{name}</b></div>
            <div className="col s6 right-align">
              <a className="delete" onClick={deleteUser(id, userId)}>șterge</a>
              {parseInt(active, 10) === 1
                ? deactivateUser ? <a className="deactivate" onClick={deactivateUser(id, userId)}>dezactivează</a> : <span>activ</span>
                : approveUser ? <a className="approve" onClick={approveUser(id, userId)}>aprobă</a> : <span>în așteptare</span> }
            </div>
          </div>
        </li>
      ))}
    </StyledList>

  </AccountItem>
);

const AccountItem = styled.li`
  

  & > .header {
    color: #FFF;
    font-weight: bold;

    a {
      color: #FFF;
    }
  }

  &&& {
    padding: 0;
    margin-bottom: 10px;

    ul {
      padding: 0 20px;
    }

    .header {
      margin: 0;
      padding: 10px 20px;
      margin-bottom: 20px;
    }
  }
`;

const StyledList = styled.ul`
  a {
    font-weight: bold;
    cursor: pointer;
  }
  a.approve {
    color: #00c853
  }
  a.deactivate {
    color: #e75b04
  }
  .header {
    background-color: grey;
  }
  a.delete {
    color: #b71c1c;
    margin-right: 10px;
  }
`;

export default Accounts;
