/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { api } from '../utils';
import { COLORS } from './colors';
import ScheduleHistory from './ScheduleHistory';
import ScheduleCurrent from './ScheduleCurrent';
import {
  daysAscending, passedDays, restrictedDaysForUser, restrictedSlotsForUser,
  getScheduleHistory, slotsStillAvailable,
} from '../utils/common';

const Schedule = () => {
  const [schedule, setSchedule] = useState({
    days: [],
    users: [],
    selectedSlots: [],
    accountUsers: [],
  });
  const [slots, setSlots] = useState([]);
  const [days, setDays] = useState([]);

  const [user, setUser] = useState('');
  const [dataVersion, setDataVersion] = useState(0);
  const [day, setDay] = useState('');
  const [slot, setSlot] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [formIsEnabled, setEnabledForm] = useState(true);
  const [fetchingData, setFetchingData] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  // eslint-disable-next-line max-len
  const getUser = (field) => (userId) => (schedule.users.find(({ id }) => id === parseInt(userId, 10)) || {})[field];
  const getUserLimit = getUser('weeks');

  useEffect(() => {
    async function fetchData() {
      const response = await api.GET('schedule');
      response.days = response.days.sort(daysAscending);
      setSchedule(response);
      setFetchingData(false);
    }
    fetchData();
  }, [dataVersion]);

  useEffect(() => {
    const scheduledDays = schedule.days
      .filter(passedDays)
      .filter(restrictedDaysForUser(getUserLimit(user)))
      .map(restrictedSlotsForUser(getUserLimit(user)))
      .sort(daysAscending);

    const [firstDay] = scheduledDays;
    if (firstDay) {
      setDay(firstDay ? firstDay.id : '');
    }
    setDays(scheduledDays);
  }, [user]);

  useEffect(() => {
    const selectedDay = days.find(({ id }) => id === parseInt(day, 10));
    setSlots(selectedDay
      ? selectedDay.slots
      : []);
    setSlot('');
  }, [day]);

  // when schedule updated on initial get or after saving a slot
  useEffect(
    () => {
      const availableSlots = slotsStillAvailable(schedule.days);
      setEnabledForm(Boolean(schedule.users.length && availableSlots));
    },
    [schedule],
  );

  const handleServerResponse = (status, success, updatedSchedule, successMessageResponse) => {
    if (status === 400) {
      setErrorMessage('A apărut o problemă!');
    }

    if (!success) {
      setErrorMessage('Intervalul tocmai a fost ocupat! Am reactualizat intervalele, încercați din nou!');
      setSchedule(updatedSchedule);
      const previousUser = user;
      // we reset the user and set it again as user change sets the days based on user settings introduced with timing scheduling
      // this should be refactored once we have enough testing coverage as this is no longer maintainable and hard to read/understand
      setUser('');
      setTimeout(() => setUser(previousUser), 0);
      setTimeout(() => setDay(''), 100);
    }

    if (success) {
      setSuccessMessage(successMessageResponse);
      setSchedule(updatedSchedule);
      setUser('');
      setTimeout(() => setDay(''), 0);
    }
  };

  const emptySlot = (slotId) => async (event) => {
    event.preventDefault();

    const { allowRemovingSchedule } = await api.GET(`schedule/${slotId}/localTime/${Date.now()}`);

    if (!allowRemovingSchedule) {
      // eslint-disable-next-line no-alert
      alert('Locul se poate elibera doar dacă au mai ramas cel puțin două ore pană la momentul programarii!');
      return;
    }

    // eslint-disable-next-line no-restricted-globals, no-alert
    if (!confirm('Sunteți sigur că renunțați la această programare?')) {
      return;
    }

    const { status, slotsCleared, ...updatedSchedule } = await api.DELETE(`schedule/${slotId}/localTime/${Date.now()}`);

    const message = slotsCleared
      ? 'Programarea a fost anulată!'
      : 'Administratorii au fost notificați că doriți să renunțați la această programare!';

    handleServerResponse(status, true, updatedSchedule, message);
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    if (submitting) {
      return;
    }

    setSubmitting(true);
    const { status, success, ...updatedSchedule } = await api.POST('schedule', { user, day, slot });
    setSubmitting(false);

    // const usersLeft = updatedSchedule.users.length;
    // Intervalele au fost reactualizate. Puteți programa o altă persoană!
    const message = 'Programarea a fost făcută!';

    handleServerResponse(status, success, updatedSchedule, message);
  };

  const history = getScheduleHistory(schedule.accountUsers, schedule.days);

  if (fetchingData) {
    return null;
  }

  return (
    <Container className="container">

      <h4 className="left-align">Fă o programare</h4>
      <h5>
        Lista cu programările pentru spovedanie se actualizează în fiecare duminică de la ora 20:30.
      </h5>
      <h6>
        Pentru fiecare persoană asociată contului, aplicația permite o
        singură programare într-un interval de două liste consecutive.

      </h6>

      {formIsEnabled ? (
        <form onSubmit={handleOnSubmit} className="col s12">

          <div className="input-field col s12">
            <select className="browser-default" value={user} onChange={(event) => setUser(event.target.value)}>
              <option value="" disabled>Alegeți o persoană</option>
              {schedule.users.map(({ id, name }) => (
                <option key={id} value={id}>{name}</option>
              ))}
            </select>
            <label className="active">Persoana asociată contului</label>
          </div>

          {user && (
          <div className="input-field col s12">
            <select className="browser-default" value={day} onChange={(event) => setDay(event.target.value)}>
              <option value="" disabled>Alegeți o zi</option>
              {days.map(({ id, name }) => (
                <option key={id} value={id}>{name}</option>
              ))}
            </select>
            <label className="active">Ziua</label>
          </div>
          )}

          <div style={{ marginTop: '40px' }}>

            {slots.map(({
              id, name, userName, color, active,
            }) => {
              const status = userName ? COLORS.INTERVAL : COLORS.USER;

              return (
                <StyledTable className="row" key={id}>
                  <div className={`col s4 ${color || status}`}>{name.replace(' - 00:00', '') || '-'}</div>
                  <div className={`col s7 ${color || status}`}>
                    <span style={{ textDecoration: active === 2 ? 'line-through' : 'none' }}>
                      {userName || '-' }
                    </span>
                    {active === 2 && (<span> (anulat)</span>)}
                  </div>
                  <div className="col s1">
                    <input
                      type="checkbox"
                      disabled={!!userName || active === 2}
                      id={`check-${id}`}
                      value={id}
                      checked={parseInt(slot, 10) === id}
                      className="filled-in"
                      onChange={(event) => setSlot(event.target.value)}
                    />
                    <label htmlFor={`check-${id}`} />
                  </div>
                </StyledTable>
              );
            })}
          </div>

          <div className="section">
            <button className="btn waves-effect waves-light" type="submit">
              Salvare
              <i className="material-icons right">send</i>
            </button>
          </div>

        </form>
      ) : (
        <div className="section warning reason">
          În acest moment nu puteți efectua o altă programare.
        </div>
      )}

      {(successMessage || errorMessage) && (
      <div className={`section ${successMessage ? 'success' : 'warning'}`}>
        {successMessage || errorMessage}
      </div>
      )}

      <ScheduleCurrent
        emptySlot={emptySlot}
        selectedSlots={schedule.selectedSlots}
      />

      <br />
      <br />

      <ScheduleHistory
        history={history}
        refetchData={() => {
          setDataVersion(dataVersion + 1);
        }}
      />

    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;

  form {
    margin-top: 25px;
  }

  .reason {
    padding: 20px;
    text-align: center;
    font-weight: normal;

    ol li {
      font-weight: normal;
    }
  }

  h5 {
    font-weight: normal;
    font-size: 15px;
    margin-bottom: 20px;
    line-height: 22px;
  }

  h6 {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 22px;
  }

  table {
    tr, th {
      border-bottom: 1px solid grey;
    }
  }
`;

const StyledTable = styled.div`
  margin-bottom: 0px;

  && > div {
    padding: 10px;
    color: #FFF;
    font-weight: bold;

    &:first-child {
      border-right: 1px solid #FFF;
    }
  }

`;

export default Schedule;
