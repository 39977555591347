/* eslint-disable max-len */
export const daysAscending = (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime();

export const getTodayDate = () => new Date().toISOString().slice(0, 10);

export const passedDays = (day) => {
  const now = new Date();
  const startOfCurrentDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

  return new Date(day.date).getTime() >= startOfCurrentDay.getTime();
};

const getSlotTime = (date, interval) => {
  const slotDate = new Date(date);
  const [startHour, startMinute] = interval.split(' - ')[0].split(':');
  const slotTime = new Date(slotDate.getFullYear(), slotDate.getMonth(), slotDate.getDate(), startHour, startMinute, 0);

  return slotTime;
};

const slotIsInTheFuture = (date, interval) => new Date().getTime() < getSlotTime(date, interval).getTime();

// user not present for userLimit weeks
// admin limits slot to no weeks
// return true if there is at least a slot with userLimit >= limits
export const restrictedDaysForUser = (userLimit) => (day) => day.slots.some(({ limits, name }) => userLimit >= limits && slotIsInTheFuture(day.date, name));

export const restrictedSlotsForUser = (userLimit) => (day) => ({
  ...day,
  slots: day.slots.filter(({ limits, name }) => userLimit >= limits && slotIsInTheFuture(day.date, name)),
});

// TODO: refactor to use the result from getAvailableSlotsForLimitedWeeks (reduce to true/false)
export const slotsStillAvailable = (days = []) => days
  .filter(passedDays)
  .reduce(
    (isAvailable, currDay) => isAvailable || currDay.slots.find(
      (currSlot) => !currSlot.user && currSlot.active === 1 && slotIsInTheFuture(currDay.date, currSlot.name),
    ),
    false,
  );

export const getAvailableSlotsForLimitedWeeks = (days) => days
  .reduce((acc, curr) => [...acc, ...curr.slots], [])
  .reduce(
    (acc, curr) => {
      const { limits, user, active } = curr;

      if (!limits) {
        return acc;
      }

      const slotIsAvailable = user === null && active === 1;

      return {
        ...acc,
        [limits]: acc[limits] || slotIsAvailable,
      };
    },
    {},
  );

export const getScheduleHistory = (accountUsers, days) => {
  const slotsForLimit = getAvailableSlotsForLimitedWeeks(days);
  const slotsAvailable = slotsStillAvailable(days);

  const availableIn = accountUsers
    .map(({ name, time, weeks }) => ({ name, time, weeks }))
    .reduce(
      (acc, curr) => ({
        ...acc,
        [curr.weeks]: acc[curr.weeks]
          ? {
            ...acc[curr.weeks],
            names: `${acc[curr.weeks].names}, ${curr.name}`,
          } : {
            names: curr.name,
            time: curr.time,
          },
      }),
      {},
    );

  return Object.keys(availableIn).map((weeks) => {
    const { time, names } = availableIn[weeks];

    let computedTime = time;

    if (!slotsAvailable) {
      computedTime = 'no-slots';
    }

    if (time === null && slotsAvailable) {
      computedTime = 'allowed';
    }

    if (weeks === '1') {
      computedTime = 'not-allowed';
    }

    if (computedTime === 'allowed' || time !== null) {
      const slotWeek = Object.keys(slotsForLimit).find((week) => week <= weeks && slotsForLimit[week] === true);

      computedTime = slotsForLimit[slotWeek]
        ? time || computedTime
        : 'not-allowed';
    }

    return {
      weeks,
      names,
      time: computedTime,
    };
  });
};
