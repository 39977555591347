import React from 'react';
import styled from 'styled-components';

const ScheduleCurrent = (props) => {
  const { selectedSlots, emptySlot } = props;

  return (
    <>
      <h4 className="left-align">Programări active</h4>

      {selectedSlots.length === 0 && (
      <span>Nu aveți programări active</span>
      )}

      {selectedSlots.length !== 0 && (
      <table>
        <thead>
          <tr>
            <th>Când</th>
            <th>Cine</th>
            <th>Acțiuni</th>
          </tr>
        </thead>
        <tbody>
          {selectedSlots.map(({
            id, userName, slotName, active, dayName, notifiedForRemoval,
          }, index) => {
            const deactivate = active === 2 || notifiedForRemoval;
            const deactivateNote = deactivate && notifiedForRemoval
              ? ' (Administratorii vor șterge programarea)'
              : ' (Anulat)';
            return (
              <tr key={index}>
                <td style={{ color: deactivate ? '#bf360c' : '#000' }}>
                  {`${dayName} / ${slotName} `}
                </td>
                <td style={{ color: deactivate ? '#bf360c' : '#000' }}>{userName}</td>
                <td style={{ color: deactivate ? '#bf360c' : '#000' }}>
                  {!deactivate && (<a className="deactivate" onClick={emptySlot(id)}>Renunță la programare</a>)}
                  {deactivate ? deactivateNote : ''}
                </td>

              </tr>
            );
          })}
        </tbody>
      </table>
      )}

    </>
  );
};

const RemoveSlot = styled.div`
  a {
    cursor: pointer;
  }
`;

export default ScheduleCurrent;
