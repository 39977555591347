/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import EditLabel from './EditLabel';
import EditUser from './EditUser';

const EditSlotForm = ({
  slotId, label, color, onSave, onCancel,
}) => {
  const [tab, setTab] = React.useState('1');

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={tab}>
        <Box>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            <Tab label="Adaugă persoană" value="1" />
            <Tab label="Adaugă etichetă" value="2" />
          </TabList>
        </Box>

        <TabPanel value="1">
          <EditUser
            slotId={slotId}
            onSave={onSave}
            onCancel={onCancel}
          />

        </TabPanel>
        <TabPanel value="2">
          <EditLabel
            slotId={slotId}
            label={label}
            colo={color}
            onSave={onSave}
            onCancel={onCancel}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default EditSlotForm;
