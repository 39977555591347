/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from 'react-router-dom';
import styled from 'styled-components';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import Profile from './components/Profile';
import ChangePassword from './components/ChangePassword';
import Schedule from './components/Schedule';
import Logout from './components/Logout';
import { AppProvider } from './context';
import {
  authenticatedUser, isAdmin, withAuth, withAdmin,
} from './utils';
import Accounts from './components/admin/Accounts';
import Notifications from './components/admin/Notifications';
import Calendar from './components/admin/calendar/Calendar';
import CalendarLive from './components/admin/calendar/CalendarLive';
import TableDraft from './components/admin/TableDraft';
import TablePublished from './components/admin/TablePublished';
import Table from './components/Table';
import Terms from './components/Terms';
import Help from './components/Help';
import Footer from './components/Footer';

const StyledNav = styled.nav`
  a.selected {
    text-decoration: underline;
    background-color: rgba(0,0,0,0.1);
  }
  .nav-wrapper {
    margin-top: 36px;
    @media only screen and (max-width: 992px) {
      margin-top: 33px;
      
      ul.admin li a {
        padding: 0 5px;
        font-size: 10px;
      }

    }  
  }
  .brand-logo {
    padding: 0 30px;
    background-color: #F7F7F7;
    top: -36px;

    img {
      height: 130px;
    }

    @media only screen and (max-width: 992px) {
      margin-left: 0px;
      padding: 0;
      left: 0;
      transform: none;
      top: -30px;
      height: 100%;

      img {
        height: 100px;
      }
    }

  }
`;

export default function App() {
  const [isAuthenticated, setAuthenticated] = useState(authenticatedUser());
  const isAdminUser = isAdmin();
  return (
    <AppProvider value={{ isAuthenticated, isAdminUser, setAuthenticated }}>
      <Router>
        <StyledNav>
          <div className="nav-wrapper blue darken-2">
            <a href={isAdminUser ? '/table-published' : '/table'} className="brand-logo">
              <img src="/logo.jpg" alt="logo" />
            </a>
            <ul id="nav-mobile" className={`right ${isAdminUser ? 'admin' : ''}`}>
              {isAuthenticated ? (
                isAdminUser ? (
                  <>
                    <li><NavLink activeClassName="selected" to="/accounts">Utilizatori</NavLink></li>
                    <li><NavLink activeClassName="selected" to="/table-published">Tabel</NavLink></li>
                    <li><NavLink activeClassName="selected" to="/table-new">Tabel Nou</NavLink></li>
                    <li><NavLink activeClassName="selected" to="/table-live">Tabel Publicat</NavLink></li>
                    <li><NavLink activeClassName="selected" to="/logout">Ieșire</NavLink></li>
                  </>
                ) : (
                  <>
                    <li><NavLink activeClassName="selected" to="/schedule">Fă o programare</NavLink></li>
                    <li><NavLink activeClassName="selected" to="/profile">Profil</NavLink></li>
                    <li><NavLink activeClassName="selected" to="/logout">Ieșire</NavLink></li>
                  </>
                )
              ) : (
                <>
                  <li><NavLink activeClassName="selected" exact to="/">Autentificare</NavLink></li>
                  <li><NavLink activeClassName="selected" exact to="/signUp">Înscriere</NavLink></li>
                </>
              )}
            </ul>
          </div>
        </StyledNav>
        <div className="App">
          <Switch>
            <Route exact path="/" component={SignIn} />
            <Route exact path="/signUp" component={SignUp} />
            <Route exact path="/profile" component={withAuth(Profile)} />
            <Route exact path="/schedule" component={withAuth(Schedule)} />
            <Route exact path="/change-password/:id?" component={withAuth(ChangePassword)} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/accounts" component={withAdmin(Accounts)} />
            <Route exact path="/notifications" component={withAdmin(Notifications)} />
            <Route exact path="/table-new" component={withAdmin(Calendar)} />
            <Route exact path="/table-live" component={withAdmin(CalendarLive)} />
            <Route exact path="/table-draft" component={withAdmin(TableDraft)} />
            <Route exact path="/table-published" component={withAdmin(TablePublished)} />
            <Route exact path="/table" component={withAuth(Table)} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/help" component={Help} />
          </Switch>

          <Footer />
        </div>
      </Router>
    </AppProvider>
  );
}
