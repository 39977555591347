import React from 'react';
import styled from 'styled-components';
import { api } from '../../utils';
import { COLORS } from '../colors';

const StyledEditLabel = styled.div`
  border: 1px solid #cfd8dc;
  border-top: 0;

  .section {
    margin-top: 30px;
  }
  .input-field {
    margin-top: 0;
  }
`;

const EditLabel = ({
  slotId, label: initialLabel, color, onSave, onCancel,
}) => {
  const [label, setLabel] = React.useState(initialLabel);
  const [useBackground, setUseBackground] = React.useState(Boolean(color));

  const saveCustomLabel = async () => {
    const { days } = await api.POST(`admin/slot/${slotId}`, { label, color: useBackground ? COLORS.LABEL : '' });

    onSave({ days });
  };

  return (
    <StyledEditLabel className="row">
      <div className="container">

        <div className="input-field">
          <label htmlFor="label" className={label ? 'active' : ''}>Adaugă numele unei persoane sau numele intervalului</label>
          <input
            type="text"
            id="label"
            value={label}
            onChange={(event) => setLabel(event.target.value)}
          />
        </div>

        <div className="input-field left-align">
          <input
            type="checkbox"
            id={`edit-check-${slotId}`}
            className="filled-in"
            checked={useBackground && 'checked'}
            onClick={() => setUseBackground(!useBackground)}
          />
          <label htmlFor={`edit-check-${slotId}`}>Folosește background pentru etichetă</label>
        </div>

        <div className="section center-align">
          <button className="btn waves-effect waves-light" type="button" onClick={onCancel} style={{ marginRight: '30px' }}>
            Cancel
            <i className="material-icons right">delete</i>
          </button>
          <button className="btn waves-effect waves-light" type="button" onClick={saveCustomLabel}>
            Salvează
            <i className="material-icons right">add</i>
          </button>
        </div>
      </div>

    </StyledEditLabel>
  );
};

export default EditLabel;
