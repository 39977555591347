import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Footer = () => (
  <Container className="help">
    <Link to="/help">Cum se folosește această aplicație</Link>

    <em>
      Pentru orice problemă tehnică, scrieți un mail la:
      <br />
      programariascor@gmail.com
    </em>

  </Container>
);

const Container = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: bold;

    em {
      display: block;
      margin-top: 20px;

      font-weight: normal;
      font-size: 12px;
      color: grey;
    }

`;

export default Footer;
