import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { authenticateUser, api, isEmail } from '../utils';
import { AppContext } from '../context';
import Help from './Help';

const SignUp = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [reTypedPassword, setReTypedPassword] = useState('');
  const [error, setError] = useState('');
  const [signedUp, setSignedUp] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { setAuthenticated } = useContext(AppContext);

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    if (submitting) {
      return;
    }

    if (!name || !email || !password || !phone) {
      setError('Toate câmpurile sunt obligatorii!');
      return;
    }

    if (!isEmail(email)) {
      setError('Adresă de email invalidă!');
      return;
    }

    if (!/^\d{10}$/.test(phone)) {
      setError('Număr de telefon invalid! Trebuie să aibă 10 cifre, ex: 0740123456');
      return;
    }

    if (!agreeTerms) {
      setError('Citiți și aprobați termenii și condițiile de utilizare!');
      return;
    }

    if (password.length < 4) {
      setError('Parola este prea scurtă!');
      return;
    }

    if (password !== reTypedPassword) {
      setError('Parolă reintrodusă greșit!');
      return;
    }

    setSubmitting(true);

    const { token, refreshToken } = await api.POST('signup', {
      name, email, password, phone,
    });

    setSubmitting(false);

    if (!token) {
      setError('Eroare la creare cont! Adresa de email este asociată unui cont existent.');
      return;
    }

    setAuthenticated(authenticateUser(token, refreshToken));

    setSignedUp(true);
  };

  if (signedUp) {
    return (<Help isSignedUp />);
  }

  return (
    <Container className="container">
      <h4>Înscriere</h4>
      <form onSubmit={handleOnSubmit}>
        <div className="input-field">
          <label htmlFor="name" className={name ? 'active' : ''}>Nume Prenume</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </div>
        <div className="input-field">
          <label htmlFor="phone" className={phone ? 'active' : ''}>Telefon</label>
          <input
            type="text"
            id="phone"
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
          />
        </div>

        <div className="input-field">
          <label htmlFor="email" className={email ? 'active' : ''}>Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>

        <div className="input-field">
          <label htmlFor="password" className={password ? 'active' : ''}>Parola</label>
          <input
            type={showPassword ? 'text' : 'password'}
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <i
            className="material-icons prefix right"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? 'visibility_off' : 'visibility'}
          </i>
        </div>

        <div className="input-field">
          <label htmlFor="reTypedPassword" className={reTypedPassword ? 'active' : ''}>Reintrodu Parola</label>
          <input
            type={showRePassword ? 'text' : 'password'}
            id="reTypedPassword"
            value={reTypedPassword}
            onChange={(event) => setReTypedPassword(event.target.value)}
          />
          <i
            className="material-icons prefix right"
            onClick={() => setShowRePassword(!showRePassword)}
          >
            {showRePassword ? 'visibility_off' : 'visibility'}
          </i>
        </div>

        <div className="input-field">
          <input type="checkbox" id="terms" checked={agreeTerms} className="filled-in" onChange={() => setAgreeTerms(!agreeTerms)} />
          <label htmlFor="terms">
            Am citit și sunt de acord cu
            {' '}
            <Link to="/terms" target="_blank">termenii si condițiile</Link>
            {' '}
            de utilizare
          </label>
        </div>

        {error && (
          <div className="error">
            {error}
          </div>
        )}

        <button className="btn waves-effect waves-light" type="submit">
          Salvare
          <i className="material-icons right">send</i>
        </button>

      </form>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;

  button {
    margin: 40px 10px 0;
  }

  .input-field .prefix.right{
    right: 6px;
    top: 8px;
    cursor: pointer;
  }

`;

export default SignUp;
