import React from 'react';
import styled from 'styled-components';

const HourOptions = () => (
  <>
    <option value="00">00</option>
    <option value="01">01</option>
    <option value="02">02</option>
    <option value="03">03</option>
    <option value="04">04</option>
    <option value="05">05</option>
    <option value="06">06</option>
    <option value="07">07</option>
    <option value="08">08</option>
    <option value="09">09</option>
    <option value="10">10</option>
    <option value="11">11</option>
    <option value="12">12</option>
    <option value="13">13</option>
    <option value="14">14</option>
    <option value="15">15</option>
    <option value="16">16</option>
    <option value="17">17</option>
    <option value="18">18</option>
    <option value="19">19</option>
    <option value="20">20</option>
    <option value="21">21</option>
    <option value="22">22</option>
    <option value="23">23</option>
  </>
);

const MinuteOptions = () => (
  <>
    <option value="00">00</option>
    <option value="01">01</option>
    <option value="02">02</option>
    <option value="03">03</option>
    <option value="04">04</option>
    <option value="05">05</option>
    <option value="06">06</option>
    <option value="07">07</option>
    <option value="08">08</option>
    <option value="09">09</option>
    <option value="10">10</option>
    <option value="11">11</option>
    <option value="12">12</option>
    <option value="13">13</option>
    <option value="14">14</option>
    <option value="15">15</option>
    <option value="16">16</option>
    <option value="17">17</option>
    <option value="18">18</option>
    <option value="19">19</option>
    <option value="20">20</option>
    <option value="21">21</option>
    <option value="22">22</option>
    <option value="23">23</option>
    <option value="24">24</option>
    <option value="25">25</option>
    <option value="26">26</option>
    <option value="27">27</option>
    <option value="28">28</option>
    <option value="29">29</option>
    <option value="30">30</option>
    <option value="31">31</option>
    <option value="32">32</option>
    <option value="33">33</option>
    <option value="34">34</option>
    <option value="35">35</option>
    <option value="36">36</option>
    <option value="37">37</option>
    <option value="38">38</option>
    <option value="39">39</option>
    <option value="40">40</option>
    <option value="41">41</option>
    <option value="42">42</option>
    <option value="43">43</option>
    <option value="44">44</option>
    <option value="45">45</option>
    <option value="46">46</option>
    <option value="47">47</option>
    <option value="48">48</option>
    <option value="49">49</option>
    <option value="50">50</option>
    <option value="51">51</option>
    <option value="52">52</option>
    <option value="53">53</option>
    <option value="54">54</option>
    <option value="55">55</option>
    <option value="56">56</option>
    <option value="57">57</option>
    <option value="58">58</option>
    <option value="59">59</option>
    <option value="60">60</option>
  </>
);

const Interval = ({
  interval, day, removeInterval, setInterval,
}) => {
  const [hourStart, minuteStart] = interval.starts.split(':');
  const [hourEnds, minuteEnds] = interval.ends.split(':');

  const changeTime = (startsOrEnds) => (hourOrMinute) => (time) => {
    const [hour, minute] = interval[startsOrEnds].split(':');

    const timeToSave = hourOrMinute === 'hour'
      ? `${time}:${minute}`
      : `${hour}:${time}`;
    setInterval(startsOrEnds, timeToSave, day.id, interval.id);
  };

  const changeStarts = changeTime('starts');
  const changeStartsHour = changeStarts('hour');
  const changeStartsMinute = changeStarts('minute');

  const changeEnds = changeTime('ends');
  const changeEndsHour = changeEnds('hour');
  const changeEndsMinute = changeEnds('minute');

  const isDisabledInterval = interval.status === 1;

  return (
    <Container key={`${day.id}-${interval.id}`}>

      <Row className="row">

        <div className="input-field col s2" onChange={(event) => changeStartsHour(event.target.value)}>
          <select className="browser-default" value={hourStart} disabled={isDisabledInterval}>
            <HourOptions />
          </select>
          <label className={hourStart ? 'active' : ''}>Ora Start</label>
        </div>
        <div>:</div>
        <div className="input-field col s2" onChange={(event) => changeStartsMinute(event.target.value)}>
          <select className="browser-default" value={minuteStart} disabled={isDisabledInterval}>
            <MinuteOptions />
          </select>
          <label className={minuteStart ? 'active' : ''}>Minut Start</label>
        </div>

        <div>până la</div>

        <div className="input-field col s2" onChange={(event) => changeEndsHour(event.target.value)}>
          <select className="browser-default" value={hourEnds} disabled={isDisabledInterval}>
            <HourOptions />
          </select>
          <label className={hourEnds ? 'active' : ''}>Oră Sf.</label>
        </div>
        <div>:</div>
        <div className="input-field col s2" onChange={(event) => changeEndsMinute(event.target.value)}>
          <select className="browser-default" value={minuteEnds} disabled={isDisabledInterval}>
            <MinuteOptions />
          </select>
          <label className={minuteEnds ? 'active' : ''}>Minut Sf.</label>
        </div>

        <div className="input-field col s2">
          <label htmlFor={`time-${day.id}-${interval.id}`} className={interval.time ? 'active' : ''}>Minute</label>
          <input
            type="text"
            id={`time-${day.id}-${interval.id}`}
            value={interval.time}
            onChange={(event) => setInterval('time', event.target.value, day.id, interval.id)}
            disabled={isDisabledInterval}
          />
        </div>

        <div className="input-field col s2" onChange={(event) => setInterval('limits', event.target.value, day.id, interval.id)}>
          <select className="browser-default" value={interval.limits} disabled={isDisabledInterval}>
            <option value="2">2 +</option>
            <option value="3">3 +</option>
            <option value="4">4 +</option>
            <option value="5">5 +</option>
          </select>
          <label className={interval.limits ? 'active' : ''}>Limită</label>
        </div>

        <div className="input-field col s2 delete">
          {!isDisabledInterval && (
            <a onClick={removeInterval(day.id, interval.id)}>
              <i className="material-icons prefix">delete</i>
            </a>
          )}
        </div>

      </Row>

    </Container>
  );
};

const Container = styled.div`
  padding-left: 10px;
  margin-top: 20px;

  @media only screen and (min-width: 992px) {
    padding-left: 100px;
  }
`;

const Row = styled.div`
    display: flex;
    align-items: center;

    select + label {
        top: 5px;
    }

    .delete {
        height: 30px;
    }
`;

export default Interval;
