import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { api } from '../../utils';
import { daysAscending } from '../../utils/common';
import { COLORS } from '../colors';
import EditSlotForm from './EditSlotForm';

const Table = () => {
  const [day, setDay] = useState('');
  const [slots, setSlots] = useState([]);
  const [editSlot, setEditSlot] = useState();
  const [checkedSlots, setCheckedSlots] = useState([]);
  const [dataVersion, setDataVersion] = useState(0);
  const [limit, setLimit] = useState(2);
  const [notifications, setNotifications] = useState([]);
  const [schedule, setSchedule] = useState({
    days: [],
  });

  useEffect(() => {
    async function fetchData() {
      const response = await api.GET('public-table');
      response.days = response.days.sort(daysAscending);

      const notificationResp = await api.GET('admin/notifications');
      setNotifications(notificationResp.filter((notif) => notif.unreadNotification));

      const daysWithNotifications = response.days.map((dayItem) => {
        const dayName = dayItem.name;

        const notificationsForDay = notificationResp.filter(
          (notification) => notification.dayName === dayName,
        );

        return {
          ...dayItem,
          notificationsNo: notificationsForDay.length,
        };
      });
      response.days = daysWithNotifications;
      setSchedule(response);

      const [firstDay] = response.days;
      if (firstDay && !day) {
        setDay(firstDay.id);
      }
    }
    fetchData();
  }, [dataVersion]);

  const checkSlot = (slotId) => (event) => {
    if (event.target.checked) {
      setCheckedSlots([...checkedSlots, slotId]);
    } else {
      setCheckedSlots(checkedSlots.filter((item) => item !== slotId));
    }
  };

  const getMessage = () => checkedSlots.map((slotId) => {
    const currSlot = slots.find((item) => item.id === slotId);

    return currSlot.name;
  }).join(', ');

  const cancelSlots = async () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm(`Sunteți sigur că doriți să anulați programările: ${getMessage()}`)) {
      await api.POST('admin/cancel-slots', { slots: checkedSlots });
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  };

  const reactivateSlots = async () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm(`Sunteți sigur că doriți să reactivați programările: ${getMessage()}`)) {
      await api.POST('admin/reactivate-slots', { slots: checkedSlots });
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  };

  const deleteSlots = async () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm(`Sunteți sigur că doriți să ștergeți programările: ${getMessage()}`)) {
      await api.POST('admin/delete-slots', { slots: checkedSlots });
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  };

  const releaseSlots = async () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm(`Sunteți sigur că doriți să aprobați renunțarea: ${getMessage()}`)) {
      await api.POST('admin/release-slots', { slots: checkedSlots });
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  };

  const setSlotsLimit = async () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm(`Sunteți sigur că doriți să schimbați limita: ${getMessage()}`)) {
      await api.PUT(`admin/set-slots-limit/${limit}`, { slots: checkedSlots });
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  };

  const deleteIntervals = async () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm(`!!!!!!!!!!! Sunteți SIGUR că doriți să ștergeți intervalele: ${getMessage()}`)) {
      await api.POST('admin/delete-intervals', { slots: checkedSlots });
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  };

  useEffect(
    () => {
      const displayDay = schedule.days.find(({ id }) => id === parseInt(day, 10));
      if (displayDay) setSlots(displayDay.slots);
    },
    [day, schedule],
  );

  return (
    <div className="container">
      <h4 className="left-align">
        Tabel publicat (
        {notifications.length}
        {' '}
        {notifications.length === 1 ? 'notificare' : 'notificări'}
        )
      </h4>

      <div className="input-field col s12">
        <select className="browser-default" value={day} onChange={(event) => setDay(event.target.value)}>
          <option value="" disabled>Alegeți o zi</option>
          {schedule.days.map(({ id, name, notificationsNo }) => (
            <option key={id} value={id}>
              {name}
              {notificationsNo >= 1 ? ` - (${notificationsNo} notificări)` : '' }
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginTop: '40px' }}>
        {slots.map((slot, index) => {
          const status = slot.userName ? COLORS.INTERVAL : COLORS.USER;
          const slotColor = slot.color || status;

          const slotWithNotification = notifications.find(
            (notification) => notification.slotId === slot.id,
          );
          const releasedSlot = slot.userName === 'loc eliberat';

          const color = slotWithNotification || releasedSlot ? COLORS.RESERVE : slotColor;

          return (
            <div key={index}>
              <StyledTable className="row">
                <div className={`col s5 ${color}`}>
                  {slot.name.replace(' - 00:00', '') || '-'}
                  {' / '}
                  {`${slot.limits}+`}
                </div>
                <div className={`col s6 ${color} truncate`}>
                  <span style={{ textDecoration: slot.active === 2 ? 'line-through' : 'none' }}>
                    {slot.userName || '-' }
                    {slotWithNotification ? ' ( renunta )' : ''}
                  </span>
                  {slot.active === 2 && (<span> (anulat)</span>)}
                  {/* eslint-disable-next-line max-len */}
                  { (parseInt(slot.user || 0, 10) <= 1 && (slot.active === 3 || slot.active === 1 || slot.user === 1)) && (
                  <i className="material-icons right" onClick={() => setEditSlot(slot.id)}>edit</i>
                  )}
                </div>
                <div className="col s1">
                  <input type="checkbox" id={`check-${slot.id}`} value={slot.id} checked={checkedSlots.includes(slot.id)} className="filled-in" onChange={checkSlot(slot.id)} />
                  <label htmlFor={`check-${slot.id}`} />
                </div>
              </StyledTable>
              {editSlot === slot.id && (
              <EditSlotForm
                slotId={slot.id}
                label={slot.userName}
                color={slot.color}
                onSave={() => {
                  setEditSlot(null);
                  setDataVersion(dataVersion + 1);
                }}
                onCancel={() => {
                  setEditSlot(null);
                }}
              />
              )}
            </div>
          );
        })}
      </div>

      {checkedSlots.length > 0 && (
      <ButtonsWrapper className="section center-align">

        <div className="row">
          <div className="input-field col s2">
            <select className="browser-default" value={limit} onChange={(event) => setLimit(event.target.value)}>
              <option value="2" enable>2+</option>
              <option value="3" enable>3+</option>
              <option value="4" enable>4+</option>
              <option value="5" enable>5+</option>
            </select>
            <label className="active">Limită</label>
          </div>
          <div className="input-field col s4">
            <button className="btn waves-effect waves-light" type="button" onClick={setSlotsLimit}>
              Setează limtă
              <i className="material-icons right">check</i>
            </button>
          </div>
          <div className="input-field col s6">
            <p>
              Acțiunea se aplică sloturilor selectate carora le schimbăm limita
            </p>
          </div>

        </div>

        <div>
          <button className="btn waves-effect waves-light" type="button" onClick={releaseSlots}>
            Aprobă renunțarea
            <i className="material-icons right">check</i>
          </button>
          <p>
            Acțiunea se aplică sloturilor selectate care au fost anulate de utilizatori.
            Acțiunea va lăsa slotul intr-un status rezervat permițand admin-ului să programeze
            o altă persoană
          </p>
        </div>
        <div>
          <button className="btn waves-effect waves-light" type="button" onClick={deleteSlots}>
            Șterge Programare
            <i className="material-icons right">delete</i>
          </button>
          <p>
            Acțiunea se aplică sloturilor selectate si va avea ca efect eliminarea persoanei
            programate sau a etichetei adăugate pe slotul respectiv
          </p>
        </div>
        <div>
          <button className="btn waves-effect waves-light" type="button" onClick={cancelSlots}>
            Anulează Intervale
            <i className="material-icons right">delete</i>
          </button>
          <p>
            Acțiunea se aplică sloturilor selectate si se vor anula programarile selectate.
            Nu se vor sterge sloturile dar persoanele nu se vor putea programa saptămana
            viitoare
          </p>
        </div>
        <div>
          <button className="btn waves-effect waves-light" type="button" onClick={reactivateSlots}>
            Activează
            <i className="material-icons right">add</i>
          </button>
          <p>
            Acțiunea se aplică sloturilor selectate si anulate prin acțiunea anterioara.
            Practic, este inversul acțiunii `Anulează Intervale`
          </p>
        </div>

        <div>
          <button className="btn waves-effect red darken-4" type="button" onClick={deleteIntervals}>
            Șterge Intervale
            <i className="material-icons right">delete</i>
          </button>
          <p>
            Acțiunea se aplică sloturilor selectate si are ca efect inlăturarea ireversibilă
            din baza de date a sloturilor impreună cu persoanele programate.
          </p>
        </div>

      </ButtonsWrapper>
      )}
    </div>
  );
};

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;

    button {
        margin-bottom: 20px;
        margin-right: 10px;
    }

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      button {
        min-width: 250px;
      }

      p {
        margin: 0
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column;

        p {
          margin-bottom: 20px;
        }
      }
    }
`;

const StyledTable = styled.div`
  margin-bottom: 0px;

  && > div {
    padding: 10px;
    color: #FFF;
    font-weight: bold;

    i {
      height: 20px;
      cursor: pointer;
    }

    &:first-child {
      border-right: 1px solid #FFF;
    }
  }
`;

export default Table;
